<template>
    <b-row>
      <b-col lg="12" sm="12">
          <b-table-simple hover bordered small>
              <b-tbody>
                  <tr>
                      <b-th colspan="8" class="text-center p-2" style="background: #e3e3e3">{{ $t('ditfConfig.fair_calendar') }}</b-th>
                  </tr>
                  <tr>
                      <b-th class="text-right">{{ $t('globalTrans.year') }}</b-th>
                      <b-td class="text-left" colspan="3">{{ $n(detailsData.year, { useGrouping : false }) }}</b-td>
                  </tr>
                  <tr>
                      <b-th class="text-right" style="width: 15%">{{ $t('globalTrans.title') }}</b-th>
                      <b-td class="text-left" colspan="3">{{ $i18n.locale === 'en' ? detailsData.title_en : detailsData.title_bn }}</b-td>
                  </tr>
                  <tr>
                      <b-th class="text-right">{{ $t('globalTrans.start_date') }}</b-th>
                      <b-td class="text-left">{{ detailsData.start_date | dateFormat }}</b-td>
                      <b-th class="text-right">{{ $t('globalTrans.end_date') }}</b-th>
                      <b-td class="text-left">{{ detailsData.end_date | dateFormat }}</b-td>
                  </tr>
                  <tr>
                      <b-th class="text-right">{{ $t('globalTrans.description') }} </b-th>
                      <b-td class="text-left" colspan="3">{{ $i18n.locale === 'en' ? detailsData.description_en : detailsData.description_bn }}</b-td>
                  </tr>
              </b-tbody>
          </b-table-simple>
      </b-col>
    </b-row>
</template>
<script>
export default {
    name: 'Details',
    props: ['id'],
    data () {
      return {
      valid: null,
      loading: false,
      errors: [],
      detailsData: []
      }
    },
    created () {
    if (this.id) {
      const tmp = this.getCommitteeInfo()
      this.detailsData = tmp
    }
    },
    methods: {
    getRoll (id) {
      const data = this.$store.state.TradeFairService.commonObj.committeeRollList.find(item => item.status === 1 && item.value === id)
      if (this.currentLocale === 'bn') {
        return data !== 'undefined' ? data.text_bn : ''
      } else {
        return data !== 'undefined' ? data.text_en : ''
      }
    },
    getCommitteeInfo () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    }
    }
}
</script>
